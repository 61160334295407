import StarPurple500RoundedIcon from "@mui/icons-material/StarPurple500Rounded";
import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import JsonEditor from "react-json-editor-ui";
import { useDispatch, useSelector } from "react-redux";
import { handleChangEditPrompt } from "../../../../store/collectionsSlice";
import { useThemeContext } from "../../../Themes/ThemeContextProvider";
import CodeHighlighter from "../../../UI/CodeHighlighter";

const sampleData = {
  Output: "Give output in 100 words",
};
const sampleData2 = {
  Data: {
    title: "title string",
    summary: "string 100-200 words",
  },
};

const sampleData3 = {
  Data: [
    "Title",
    {
      Description: "give description in 50 words ",
    },

    "tags",
  ],
};

const sampleData4 = {
  data: [
    {
      title: "string with 5-10 words",
      article: "valid json compliant text with 500 or more words",
      short_info: "valid json compliant String with 10-20 words",
    },
  ],

  data_tag: "value",
};

const JsonFormatter = (props) => {
  const { activeScreen = "Preview" } = props;
  const { colors, mode } = useThemeContext();
  const editPromptsList = useSelector(
    (state) => state.collectionsSlice.editPromptsList
  );
  const modelsVersionsList = useSelector(
    (state) => state.collectionsSlice.modelsVersionsList
  );
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};
  const isImageProcessType =
    modelsVersionsList?.filter((i) => i?.id === promptData?.llmVersion)[0]
      ?.model_type === "Image";
  let outputData = promptData.output_keys[promptData?.outputFormat];

  const [editObject, setEditObject] = useState(
    isImageProcessType
      ? { data: [{ url: "string" }] }
      : outputData
      ? outputData
      : { data: [{ title: "string", description: "string" }] }
  );

  const dispatch = useDispatch();
  const [showEditor, setShowEditor] = useState(true);
  const [showNewEditor, setShowNewEditor] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);

  const handleEditObject = () => {
    const isImageProcessType =
      modelsVersionsList?.filter((i) => i?.id === promptData?.llmVersion)[0]
        ?.model_type === "Image";
    let imageOutputFormat = { data: [] };
    setShowEditor(true);
    if (isImageProcessType && promptData?.variationCount) {
      let data = [];
      for (let index = 0; index < promptData?.variationCount; index++) {
        data.push({ url: "string" });
      }
      imageOutputFormat["data"] = data;
      setEditObject(imageOutputFormat);
    } else {
      setEditObject(
        outputData
          ? outputData
          : { data: [{ title: "string", description: "string" }] }
      );
    }
  };

  useEffect(() => {
    setShowEditor(false);
    setEditObject({});
    let handleEditor = setTimeout(() => {
      handleEditObject();
    }, 500);
    return () => clearTimeout(handleEditor);
  }, [promptData?.llmVersion, promptData?.variationCount]);

  useEffect(() => {
    setEditObject({});
    setShowEditor(false);
    let handleEditor = setTimeout(() => {
      handleEditObject();
    }, 500);
    return () => clearTimeout(handleEditor);
  }, [promptData?.id]);

  useMemo(() => {
    let data1 = {
      ...promptData,
      output_keys: {
        ...promptData.output_keys,
        json: JSON.parse(JSON.stringify(editObject)),
      },
    };
    dispatch(handleChangEditPrompt(data1));
  }, [editObject]);

  const handleClick = (type) => {
    setShowNewEditor(true);
    setEditObject(type);
    setIsConfirmModalOpen(false);
    setIsModalOpen(false);
  };

  const formatButtons = [
    { label: "String example", value: sampleData },
    { label: "Object example", value: sampleData2 },
    { label: "Array example", value: sampleData3 },
    { label: "Nested example", value: sampleData4 },
  ];

  const renderFormattedData = (data, indentLevel = 0) => {
    const isObject = typeof data === "object" && data !== null;
    const indentStyle = {
      marginLeft: `${indentLevel * 10}px`,
      fontSize: "0.9rem",
    };

    if (typeof data === "string" || typeof data === "number") {
      return (
        <Typography
          style={indentStyle}
          className="text-sm text-green-800 dark:text-orange-300"
        >
          "{data}"
        </Typography>
      );
    } else if (Array.isArray(data)) {
      return (
        <div style={indentStyle} className="text-sm">
          <Typography
            component="span"
            style={{ fontSize: "0.9rem", fontWeight: 600 }}
          >
            [
          </Typography>

          <ul style={{ padding: 0, margin: 0 }}>
            {data.map((item, index) => (
              <li key={index} style={{ listStyle: "none" }}>
                {renderFormattedData(item, indentLevel + 1)}
              </li>
            ))}
          </ul>

          <Typography
            component="span"
            style={{ fontSize: "0.9rem", fontWeight: 600 }}
          >
            ],
          </Typography>
        </div>
      );
    } else if (isObject) {
      return (
        <div style={indentStyle} className="text-sm">
          <Typography
            component="span"
            style={{ fontSize: "0.9rem", fontWeight: 600 }}
          >
            {"{"}
          </Typography>

          <div>
            {Object.entries(data).map(([key, val]) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  ...indentStyle,
                }}
              >
                <Typography
                  style={{ fontSize: "0.9rem" }}
                  className="text-gray-800 dark:text-blue-300"
                >
                  "{key}":
                </Typography>
                <span>{renderFormattedData(val, indentLevel + 1)}</span>
              </div>
            ))}
          </div>

          <Typography
            component="span"
            style={{ fontSize: "0.9rem", fontWeight: 600 }}
          >
            {"}"}
          </Typography>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <div className="flex gap-2 mb-[1%] pt-[1%] justify-between mx-4 items-center">
        {formatButtons.map((button) => (
          <div
            key={button.label}
            className={`flex items-center cursor-pointer bg-primary border-2 border-secondary rounded-lg px-2 py-1 `}
          >
            <StarPurple500RoundedIcon
              className="text-secondary"
              style={{ height: "16px" }}
            />
            <button
              className="text-secondary"
              onClick={() => {
                setSelectedContent(button.value);
                setIsModalOpen(true);
              }}
            >
              {button.label}
            </button>
          </div>
        ))}
      </div>

      {promptData?.outputFormat && (
        <div className="p-3 rounded-b-lg bg-secondary">
          {activeScreen !== promptData?.outputFormat ? (
            <div className={`relative scal-json-formatter`}>
              {showEditor && !showNewEditor && (
                <JsonEditor
                  data={editObject}
                  onChange={(data) => setEditObject(data)}
                />
              )}
              {!!showNewEditor && (
                <JsonEditor
                  data={editObject}
                  onChange={(data) => setEditObject(data)}
                />
              )}
            </div>
          ) : (
            <div>
              <CodeHighlighter
                customStyle={{ background: "transparent" }}
                style={mode === "dark" ? "vs2015" : "a11yLight"}
                language={"json"}
                wrapLongLines={true}
                wrapLines={true}
              >
                {JSON.stringify(editObject, undefined, 2)}
              </CodeHighlighter>
            </div>
          )}
        </div>
      )}

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "90%",
            bgcolor: "background.paper",
            border: "1px ",
            borderRadius: "8px",
            boxShadow: 24,
            p: 1,
          }}
        >
          <div className="border-2 border-secondary bg-secondary py-8 px-4 rounded-lg">
            <Box id="modal-description" sx={{ mt: 2, mb: 2 }}>
              {selectedContent && renderFormattedData(selectedContent)}
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setIsModalOpen(false);
                setIsConfirmModalOpen(true);
              }}
              sx={{ mt: 2 }}
            >
              Use example
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setIsModalOpen(false)}
              sx={{ mt: 2, ml: 2 }}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        aria-labelledby="confirm-modal-title"
        aria-describedby="confirm-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            bgcolor: "background.paper",
            border: "1px solid gray",
            borderRadius: "8px",
            boxShadow: 24,
            p: 2,
          }}
        >
          <Typography id="confirm-modal-description" sx={{ mt: 2 }}>
            This will replace your last changes. Do you want to continue?
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleClick(selectedContent)}
            sx={{ mt: 2 }}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setIsModalOpen(false);
              setIsConfirmModalOpen(false);
            }}
            sx={{ mt: 2, ml: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default JsonFormatter;

// ${
//   showNewEditor ? "hidden" : "block"
// }
