import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchAdminAnalytics } from "../../services/app/api";
import snackbar from "../../services/app/snackbar";
import { formatTimestampToDate } from "../../services/generalFunctions";
import { handleLoading } from "../../store/collectionsSlice";
import MuiSelect from "../UI/MuiSelect";
import MuiTable from "../UI/MuiTable";

const AdminAnalytics = () => {
  const dispatch = useDispatch();
  const [errorType, SetErrorType] = useState("system_error");
  const [allAnalytics, SetAllAnalytics] = useState({ items: [], pages: 0 });
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    return today.toISOString().split("T")[0];
  });
  const [endDate, setEndDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  });
  //   const [page, setPage] = useState(1);

  const menuList = [
    { value: "system_error", label: "System errors" },
    { value: "prompt_error", label: "Prompt errors" },
    { value: "scalaix_error", label: "Scalaix errors" },
    { value: "model_execution_error", label: "Execution errors" },
  ];

  const columns = [
    {
      field: "team_id",
      title: "Team ID",
      renderAction: (row) => (
        <span>
          <p className="text-xs">{row?.team_id}</p>
        </span>
      ),
    },
    {
      field: "user_id",
      title: "User ID",
      renderAction: (row) => (
        <span>
          <p className="text-xs">{row?.user_id}</p>
        </span>
      ),
    },
    {
      field: "prompt_id",
      title: "Prompt ID",
      renderAction: (row) => (
        <span>
          <p className="text-xs">{row?.prompt_id}</p>
        </span>
      ),
    },
    {
      field: "created_at",
      title: "Created at",
      renderAction: (row) => (
        <span>
          <p className="text-xs">{formatTimestampToDate(row?.created_at)}</p>
        </span>
      ),
    },
  ];

  const convertDateToUTC = (date) => {
    return new Date(date).getTime() / 1000;
  };

  const callAnalytics = (page = 1) => {
    const params = {
      tag: "",
      start_date: convertDateToUTC(startDate),
      end_date: convertDateToUTC(endDate),
      error_tag: errorType,
      page: page,
    };
    dispatch(handleLoading(true));
    fetchAdminAnalytics(params)
      .then((data) => {
        try {
          SetAllAnalytics(data);
        } catch (error) {
          snackbar.error({
            title: "Error while setting data",
          });
        }
      })
      .catch((error) => {
        snackbar.error({
          title: `${error?.error?.toString() || "No Data"}`,
        });
      })
      .finally(() => {
        dispatch(handleLoading(false));
      });
  };

  useEffect(() => {
    let debounceSearch = setTimeout(() => {
      callAnalytics();
    }, 500);
    return () => clearTimeout(debounceSearch);
  }, [startDate, endDate, errorType]);

  const today = new Date().toISOString().split("T")[0];

  const collapsibleCaller = (row) => {
    return (
      <div className="my-4 w-[1000px]">
        {errorType === "prompt_error" ? (
          <p className="text-orange-600">{row?.response}</p>
        ) : (
          <p className="text-orange-600">{row?.error}</p>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="display-md-semibold mx-[75px] mt-[3%]">
        <h1 className=" text-primary">Admin Analytics</h1>
      </div>
      <div className="mx-[75px] mt-[2%] flex items-center space-x-8 ">
        <div className="w-52 ">
          <MuiSelect
            menuItems={menuList}
            value={errorType}
            onChange={(e) => SetErrorType(e.target.value)}
          />
        </div>
        <div className="flex space-x-4">
          <div>
            <label className="mr-2" htmlFor="start-date">
              Start Date:
            </label>
            <input
              id="start-date"
              type="date"
              value={startDate}
              max={today}
              onChange={(e) => setStartDate(e.target.value)}
              className="border border-secondary bg-secondary rounded-lg px-2 py-[7px]"
            />
          </div>

          <div>
            <label className="mr-2" htmlFor="end-date">
              End Date:
            </label>
            <input
              id="end-date"
              type="date"
              value={endDate}
              max={today}
              onChange={(e) => setEndDate(e.target.value)}
              className="border border-secondary bg-secondary rounded-lg px-2 py-[7px]"
            />
          </div>
        </div>
      </div>

      <div className="mx-[75px]  mt-[2%]">
        <MuiTable
          data={allAnalytics.items}
          columns={columns}
          page={allAnalytics?.page}
          pages={allAnalytics?.pages}
          onPageChange={(v, page) => callAnalytics(page)}
          tableTitle="Error Analytics"
          collapsible={true}
          collapsibleCaller={collapsibleCaller}
        />
      </div>
    </>
  );
};

export default AdminAnalytics;
