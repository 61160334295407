import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import { useDispatch, useSelector } from "react-redux";
import { getElementWidthById } from "../../../../services/generalFunctions";
import { changeActiveWindow } from "../../../../store/collectionsSlice";
import { EnvironmentIcon, FoldersIcon } from "../../../UI/IconPack";
import ContextList from "../context/ContextList";
import EnvironmentsList from "../environment/EnvironmentsList";
import PromptsList from "./PromptsList";

const PromptDrawer = (props) => {
  const { handlePromptDrawer, openPromptDrawer, handleOpenPromptDrawer } =
    props;
  const activeWindow = useSelector(
    (state) => state.collectionsSlice.activeWindow
  );
  const dispatch = useDispatch();
  const components = {
    collection: <PromptsList />,
    environment: <EnvironmentsList />,
    context: <ContextList />,
  };
  const icons = {
    collection: <FoldersIcon height={20} width={20} />,
    environment: <EnvironmentIcon height={20} width={20} />,
    context: <DnsOutlinedIcon sx={{ fontSize: 20 }} />,
  };
  const handleChangeScreen = (screen) => {
    dispatch(changeActiveWindow(screen));
  };
  return (
    <div
      style={{ height: "100vh" }}
      className="flex z-20 sticky top-0"
      onMouseLeave={handlePromptDrawer}
    >
      <div
        id="prompt-drawer"
        className={`w-full pt-8 border-1 border-primary flex flex-col justify-between items-center`}
      >
        <div>
          {Object.keys(icons).map((iconName) => (
            <div
              key={iconName}
              onClick={() => {
                handleChangeScreen(iconName);
                handleOpenPromptDrawer();
              }}
              onMouseEnter={
                activeWindow == iconName ? handleOpenPromptDrawer : () => {}
              }
              className={`rounded-lg m-1 h-12 w-12 cursor-pointer  ${
                activeWindow == iconName
                  ? "bg-tertiary text-primary"
                  : "text-[#94969C]"
              } flex items-center flex-col justify-center `}
              title={iconName}
            >
              {icons[iconName]}
            </div>
          ))}
        </div>
      </div>

      {activeWindow && openPromptDrawer && (
        <div
          onMouseLeave={handlePromptDrawer}
          className="absolute bg-secondary w-72 h-full  "
          style={{
            left: `${getElementWidthById("prompt-drawer")}px`,
          }}
        >
          {components[activeWindow]}
        </div>
      )}
    </div>
  );
};

export default PromptDrawer;
