import env from "@Services.Core/env";
import http from "@Services.Core/http";
const makeApiUrl = (url) => `${env.getApiBaseUrl()}${url}`;
const makeAapiUrl = (url) => `${env.getAapiBaseUrl()}${url}`;
const makeSapiUrl = (url) => `${env.getSapiBaseUrl()}${url}`;
const makeIapiUrl = (url) => `${env.getIapiBaseUrl()}${url}`;
const makePapiUrl = (url) => `${env.getPapiBaseUrl()}${url}`;
const makeGraphapiUrl = (url) => `${env.getGraphBaseUrl()}${url}`;
const makesubmitemailUrl = (url) => `${env.getSubmitEmailUrl()}${url}`;
const makeUnPlashUrl = (url) => `${env.getUnsplashBaseUrl()}${url}`;
const getUnPlashUrl = () => `${env.getUnsPlashKey()}`;
// Login
export const login = ({ data }) => {
  return http.post({ url: makeAapiUrl("signin"), data });
};

// Social Log in
export const socialLogin = ({ data }) => {
  return http.post({ url: makeApiUrl("sign/in"), data });
};

export const signUp = ({ data }) => {
  return http.post({ url: makeApiUrl(`register`), data });
};

export const saveApiKey = ({ user_id, data }) => {
  return http.post({ url: makeApiUrl(`user/${user_id}/api/tokens`), data });
};

export const getApiKeys = (user_id) => {
  return http.get({ url: makeApiUrl(`user/${user_id}/api/tokens`) });
};
export const getLLMKeys = (team_id) => {
  return http.get({ url: makeApiUrl(`team/${team_id}/apikeys`) });
};

export const deleteKey = (user_id, id) => {
  return http.delete({ url: makeApiUrl(`user/${user_id}/api/tokens/${id}`) });
};

export const getAllPrompts = ({ collection_id, params }) => {
  return http.get({
    url: makeApiUrl(`collection/${collection_id}/prompts`),
    params,
  });
};
export const getPromptDetail = (prompt_id) => {
  return http.get({
    url: makeApiUrl(`prompt/${prompt_id}`),
  });
};
export const deletePrompt = (prompt_id) => {
  return http.delete({ url: makeApiUrl(`prompt/${prompt_id}`) });
};

export const executePrompt = ({ team_id, data }) => {
  return http.post({ url: makeApiUrl(`team/${team_id}/execute/prompt`), data });
};

export const savePrompt = ({ collection_id, data }) => {
  return http.post({
    url: makeApiUrl(`collection/${collection_id}/prompts`),
    data,
  });
};

export const updatePrompt = ({ prompt_id, data }) => {
  return http.put({
    url: makeApiUrl(`prompt/${prompt_id}`),
    data,
  });
};

export const forgotPassword = ({ data }) => {
  return http.post({ url: makeApiUrl(`forgot/password`), data });
};
export const resetPassword = ({ data }) => {
  return http.put({ url: makeApiUrl(`change/password`), data });
};

export const createNewTeam = ({ user_id, data }) => {
  return http.post({ url: makeApiUrl(`user/${user_id}/team`), data });
};
export const getTeam = (user_id, team_id) => {
  return http.get({ url: makeApiUrl(`user/${user_id}/team/${team_id}`) });
};

export const getGeneralDocumentation = (team_id) => {
  return http.get({ url: makeApiUrl(`team/${team_id}/documentation/module`) });
};
export const getTeamCollectionDocumentation = ({ team_id, params }) => {
  return http.get({
    url: makeApiUrl(`team/${team_id}/collection/documentation/module`),
    params,
  });
};
export const getCalculation = ({ params }) => {
  return http.get({
    url: makeApiUrl(`token/calculation`),
    params,
  });
};
export const getBenchMarkResult = ({ params }) => {
  return http.get({
    url: makeApiUrl(`benchmark/results`),
    params,
  });
};

export const getMyTeams = (user_id) => {
  return http.get({ url: makeApiUrl(`user/${user_id}/team`) });
};
export const getPromptDataAnalytics = (req_id) => {
  return http.get({ url: makeApiUrl(`response/${req_id}/execution/details`) });
};
export const validateMeToAddInTeam = ({ data }) => {
  return http.post({ url: makeApiUrl(`invite/accept`), data });
};
export const signUpOnInvitation = ({ data }) => {
  return http.post({ url: makeApiUrl(`signup/invitation`), data });
};
export const getMyCollections = ({ team_id, params }) => {
  return http.get({ url: makeApiUrl(`team/${team_id}/collections`), params });
};
export const addNewCollection = ({ data }) => {
  return http.post({ url: makeApiUrl(`collection`), data });
};
export const editCollection = ({ collection_Id, data }) => {
  return http.put({ url: makeApiUrl(`collection/${collection_Id}`), data });
};
export const getCollection = ({ collection_Id, params = {} }) => {
  return http.get({ url: makeApiUrl(`collection/${collection_Id}`), params });
};
export const getCollectionVersions = ({ collection_Id, params = {} }) => {
  return http.get({
    url: makeApiUrl(`collection/${collection_Id}/version/history`),
    params,
  });
};
export const deleteCollection = (collection_Id) => {
  return http.delete({ url: makeApiUrl(`collection/${collection_Id}`) });
};

export const addNewEnvironment = ({ team_id, data }) => {
  return http.post({ url: makeApiUrl(`team/${team_id}/environment`), data });
};
export const getMyEnvironments = ({ team_id, params }) => {
  return http.get({ url: makeApiUrl(`team/${team_id}/environment`), params });
};
export const editEnvironment = ({ environment_id, data }) => {
  return http.put({
    url: makeApiUrl(`team/environment/${environment_id}`),
    data,
  });
};
export const deleteEnvironment = (environment_id) => {
  return http.delete({ url: makeApiUrl(`team/environment/${environment_id}`) });
};

export const getAIModels = ({ params }) => {
  return http.get({ url: makeApiUrl(`model`), params });
};

export const getObjectives = () => {
  return http.get({ url: makeApiUrl(`objective`) });
};

export const publishCollection = ({ collection_id, data }) => {
  return http.post({
    url: makeApiUrl(`collection/${collection_id}/published`),
    data,
  });
};

export const getMyLLMKey = ({ user_id, team_id, params }) => {
  return http.get({
    url: makeApiUrl(`user/${user_id}/team/${team_id}/llm/token`),
    params,
  });
};

export const addNewLLMKey = ({ user_id, team_id, data }) => {
  return http.post({
    url: makeApiUrl(`user/${user_id}/team/${team_id}/llm/token`),
    data,
  });
};
export const updateLLMKey = ({ user_id, token_id, data }) => {
  return http.put({
    url: makeApiUrl(`user/${user_id}/llm/token/${token_id}`),
    data,
  });
};
export const deleteLLMKey = ({ user_id, token_id }) => {
  return http.delete({
    url: makeApiUrl(`user/${user_id}/llm/token/${token_id}`),
  });
};

export const getTeamAuthKey = ({ team_id, params = {} }) => {
  return http.get({
    url: makeApiUrl(`team/${team_id}/authkey`),
    params,
  });
};

export const addTeamAuthKey = ({ team_id, data }) => {
  return http.post({
    url: makeApiUrl(`team/${team_id}/authkey`),
    data,
  });
};
export const updateTeamAuthKey = ({ key_id, data }) => {
  return http.put({
    url: makeApiUrl(`authkey/${key_id}`),
    data,
  });
};
export const deleteTeamAuthKey = ({ key_id }) => {
  return http.delete({
    url: makeApiUrl(`authkey/${key_id}`),
  });
};

export const inviteToTeam = ({ data }) => {
  return http.post({
    url: makeApiUrl(`team/invite`),
    data,
  });
};

export const setActiveTeam = ({ team_id }) => {
  return http.put({
    url: makeApiUrl(`team/${team_id}/setactive`),
  });
};
export const imageToBase64Image = ({ data }) => {
  return http.post({
    url: makeApiUrl(`image/data`),
    data,
  });
};

export const getTemplatesTags = () => {
  return http.get({
    url: makeApiUrl(`collection/tags`),
  });
};
export const getTemplatesCollections = ({ params = {} }) => {
  return http.get({
    url: makeApiUrl(`template/collections`),
    params,
  });
};
export const addTemplateCollection = ({ team_id, data }) => {
  return http.post({
    url: makeApiUrl(`team/${team_id}/template/collection`),
    data,
  });
};

// get user details
export const getUserDetails = (user_id) => {
  return http.get({ url: makeApiUrl(`user/${user_id}`) });
};

export const updateUserDetails = ({ user_id, data }) => {
  return http.put({ url: makeApiUrl(`user/${user_id}`), data });
};

export const verifyEmail = ({ user_id, data }) => {
  return http.post({
    url: makeApiUrl(`user/${user_id}/verify/email`),
    data,
  });
};

export const changeEmail = ({ data }) => {
  return http.post({
    url: makeApiUrl(`verify/email`),
    data,
  });
};

export const changeInApp = ({ data, user_id }) => {
  return http.put({
    url: makeApiUrl(`user/${user_id}/new/password`),
    data,
  });
};

export const uploadDataRoomFile = ({ data, team_id, onUploadProgress }) => {
  return http.post({
    url: makeApiUrl(`file/upload/team/${team_id}`),
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
};

export const getAllDataRoomFiles = ({ team_id, params = {} }) => {
  return http.get({
    url: makeApiUrl(`team/${team_id}/file`),
    params,
  });
};
export const deleteDataRoomFile = ({ file_id }) => {
  return http.delete({
    url: makeApiUrl(`file/${file_id}`),
  });
};
export const deleteSpaceFromTeam = ({ file_id }) => {
  return http.delete({
    url: makeApiUrl(`space/${file_id}/delete`),
  });
};
export const embedFile = ({ data }) => {
  return http.post({
    url: makeApiUrl(`file/embed`),
    data,
  });
};
export const updateDataRoomFile = ({
  file_id,
  data,
  onUploadProgress = () => {},
}) => {
  return http.put({
    url: makeApiUrl(`file/${file_id}`),
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
};
export const getAttachedFileModel = ({ file_id }) => {
  return http.get({
    url: makeApiUrl(`file/${file_id}/attached?page=1&limit=10`),
  });
};
export const attachedFileModel = ({ file_id, data }) => {
  return http.post({
    url: makeApiUrl(`file/${file_id}/attached`),
    data,
  });
};
export const updateAttachedFileModel = ({ file_map_id, data }) => {
  return http.post({
    url: makeApiUrl(`attache/file/${file_map_id}`),
    data,
  });
};
export const deleteAttachedFileModel = ({ file_map_id }) => {
  return http.delete({
    url: makeApiUrl(`attache/file/${file_map_id}`),
  });
};

export const createAIAssistant = ({ team_id, data }) => {
  return http.post({
    url: makeApiUrl(`team/${team_id}/assistance`),
    data,
  });
};

export const getAllAIAssistant = ({ team_id, params = {} }) => {
  return http.get({
    url: makeApiUrl(`team/${team_id}/assistance`),
    params,
  });
};
export const getAllAIAssistantChildren = ({ assistance_id, params = {} }) => {
  return http.get({
    url: makeApiUrl(`assistance/${assistance_id}/childs`),
    params,
  });
};

export const updateAIAssistant = ({ assistance_id, data }) => {
  return http.put({
    url: makeApiUrl(`assistance/${assistance_id}`),
    data,
  });
};
export const deleteAIAssistant = ({ assistance_id }) => {
  return http.delete({
    url: makeApiUrl(`assistance/${assistance_id}`),
  });
};

export const getFilesAttachedToModel = ({ team_id, model_id }) => {
  return http.get({
    url: makeApiUrl(`team/${team_id}/model/${model_id}/files`),
  });
};

export const getAssistanceByModelVersion = ({ team_id, version_id }) => {
  return http.get({
    url: makeApiUrl(`team/${team_id}/version/${version_id}/assistance`),
  });
};

export const conversationWithAssistant = ({ assistance_id, data }) => {
  return http.post({
    url: makeApiUrl(`assistance/${assistance_id}/conversation`),
    data,
  });
};

export const restoreAssistant = ({ assistance_id }) => {
  return http.get({
    url: makeApiUrl(`assistance/${assistance_id}/restored`),
  });
};
export const restoreDataRoomFiles = ({ file_map_id }) => {
  return http.get({
    url: makeApiUrl(`attache/file/${file_map_id}/restored`),
  });
};

export const getPromptVersions = (id) => {
  return http.get({ url: makeApiUrl(`prompt/${id}/versions`) });
};

export const getAssistanceVersion = (prompt_id, tag) => {
  return http.get({
    url: makeApiUrl(`prompt/${prompt_id}/versions/${tag}`),
  });
};
export const publishAssistant = (assistance_id) => {
  return http.get({
    url: makeApiUrl(`assistance/${assistance_id}/publish/docs`),
  });
};
export const getAssistantEmbeddedCode = (assistance_id, tags) => {
  return http.get({
    url: makeApiUrl(`assistance/${assistance_id}/${tags}`),
  });
};

export const uploadDataRoomLink = ({ team_id, data }) => {
  return http.post({
    url: makeApiUrl(`link/upload/team/${team_id}`),
    data,
  });
};

export const addIntegrationApp = ({ team_id, data }) => {
  return http.post({
    url: makeApiUrl(`team/${team_id}/integrations`),
    data,
  });
};

export const getIntegratedAppProjects = ({
  team_id,
  integration_id,
  params,
}) => {
  return http.get({
    url: makeApiUrl(`team/${team_id}/integrations/${integration_id}/spaces`),
    params,
  });
};

export const getDataRoomTags = ({ team_id, params }) => {
  return http.get({
    url: makeApiUrl(`team/${team_id}/file/tags`),
    params,
  });
};

export const attachTagToModel = ({ team_id, tag, data }) => {
  return http.post({
    url: makeApiUrl(`team/${team_id}/attach/${tag}`),
    data,
  });
};

export const attachAssetsProgress = ({ team_id, data }) => {
  return http.post({
    url: makeApiUrl(`team/${team_id}/attach/status`),
    data,
  });
};

export const createNewDataRoomTag = ({ team_id, data }) => {
  return http.post({
    url: makeApiUrl(`team/${team_id}/file/tags`),
    data,
  });
};
export const deleteDataRoomTag = ({ tag_id }) => {
  return http.delete({
    url: makeApiUrl(`tag/${tag_id}`),
  });
};

export const getAssistantAnalytics = ({ assistance_id, params }) => {
  return http.get({
    url: makeApiUrl(`assistance/${assistance_id}/analytics`),
    params,
  });
};

export const getPromptAnalytics = ({ prompt_id, params }) => {
  return http.get({
    url: makeApiUrl(`prompt/${prompt_id}/analytics`),
    params,
  });
};

export const getAssistantErrors = ({ assistance_id, params }) => {
  return http.get({
    url: makeApiUrl(`assistance/${assistance_id}/errors`),
    params,
  });
};

export const getPromptErrors = ({ prompt_id, params }) => {
  return http.get({
    url: makeApiUrl(`prompt/${prompt_id}/errors`),
    params,
  });
};

export const updateAssistantErrors = ({ error_id, data }) => {
  return http.put({
    url: makeApiUrl(`assistance/analytics/${error_id}`),
    data,
  });
};

export const updatePromptErrors = ({ error_id, data }) => {
  return http.put({
    url: makeApiUrl(`prompt/analytics/${error_id}`),
    data,
  });
};

export const buyTokens = ({ team_id, data }) => {
  return http.post({
    url: makeApiUrl(`team/${team_id}/buy/tokens`),
    data,
  });
};

export const updatePaymentStatus = ({ transaction_id, data }) => {
  return http.put({
    url: makeApiUrl(`transaction/${transaction_id}`),
    data,
  });
};

export const getPaymentHistory = ({ team_id, params }) => {
  return http.get({
    url: makeApiUrl(`team/${team_id}/transactions`),
    params,
  });
};
export const getCallBackUrls = ({ team_id, params }) => {
  return http.get({
    url: makeApiUrl(`team/${team_id}/call/backs`),
    params,
  });
};

export const getCallBackByAuth = ({ auth, params }) => {
  return http.get({
    url: makeApiUrl(`auth/${auth}/call/backs`),
    params,
  });
};

export const getTokenTrack = ({ team_id }) => {
  return http.get({
    url: makeApiUrl(`team/${team_id}/tokens/tracks`),
  });
};

export const getAllContexts = ({ team_id, params }) => {
  return http.get({
    url: makeApiUrl(`team/${team_id}/context`),
    params,
  });
};

export const updateContexts = ({ context_id, data }) => {
  return http.put({
    url: makeApiUrl(`context/${context_id}`),
    data,
  });
};

export const createContexts = ({ team_id, data }) => {
  return http.post({
    url: makeApiUrl(`team/${team_id}/context`),
    data,
  });
};
export const createCallbackUrl = ({ team_id, data }) => {
  return http.post({
    url: makeApiUrl(`team/${team_id}/call/backs`),
    data,
  });
};
export const updateCallbackUrl = ({ id, data }) => {
  return http.put({
    url: makeApiUrl(`call/backs/${id}`),
    data,
  });
};
export const deleteContext = (context_id) => {
  return http.delete({
    url: makeApiUrl(`context/${context_id}`),
  });
};

export const getTransformers = ({ params }) => {
  return http.get({
    url: makeApiUrl(`transformer`),
    params,
  });
};

export const addIntegrationTool = ({ team_id, data }) => {
  return http.get({
    url: makeApiUrl(`team/${team_id}/integrations`),
    data,
  });
};
export const integrateConfluence = ({ team_id, data }) => {
  return http.post({
    url: makeApiUrl(`team/${team_id}/confluence/integration`),
    data,
  });
};

export const getAllIntegrationTools = ({ team_id, params }) => {
  return http.get({
    url: makeApiUrl(`team/${team_id}/integrations`),
    params,
  });
};

export const getDriveLogInUri = ({ team_id, params }) => {
  return http.get({
    url: makeApiUrl(`team/${team_id}/google/drive/auth/url`),
    params,
  });
};
export const getNotifications = ({ team_id, params }) => {
  return http.get({
    url: makeApiUrl(`team/${team_id}/notifications`),
    params,
  });
};

export const getCount = ({ team_id, params }) => {
  return http.get({
    url: makeApiUrl(`team/${team_id}/notification/count`),
    params,
  });
};

export const getModelPrice = () => {
  return http.get({
    url: makeApiUrl(`model/pricing`),
  });
};
export const makerAsRead = ({ id, data }) => {
  return http.put({
    url: makeApiUrl(`notification/${id}`),
    data,
  });
};
export const addDriveAccount = ({ team_id, data }) => {
  return http.post({
    url: makeApiUrl(`team/${team_id}/google/auth/drive`),
    data,
  });
};
export const deleteIntegrationTool = ({ team_id, integration_id }) => {
  return http.delete({
    url: makeApiUrl(`team/${team_id}/integration/${integration_id}/update`),
  });
};

export const getConfluenceSpaces = ({ team_id, integration_id, params }) => {
  return http.get({
    url: makeApiUrl(`team/${team_id}/integrations/${integration_id}/spaces`),
    params,
  });
};

export const getIntegratedConfluenceSpaces = ({
  team_id,
  integration_id,
  params,
}) => {
  return http.get({
    url: makeApiUrl(`team/${team_id}/integration/${integration_id}/spaces`),
    params,
  });
};

export const getIntegratedConfluenceFolders = ({
  team_id,
  space_id,
  params,
}) => {
  return http.get({
    url: makeApiUrl(`team/${team_id}/space/${space_id}/data`),
    params,
  });
};

export const getIntegratedConfluenceSubFolders = ({ file_id, params }) => {
  return http.get({
    url: makeApiUrl(`file/${file_id}/data`),
    params,
  });
};

export const integrateSpace = ({ team_id, integration_id, data }) => {
  return http.post({
    url: makeApiUrl(`team/${team_id}/integrations/${integration_id}/integrate`),
    data,
  });
};

export const retryIntegrateSpace = ({ space_id }) => {
  return http.put({
    url: makeApiUrl(`space/${space_id}`),
  });
};
export const deleteIntegrateSpace = ({ space_id }) => {
  return http.delete({
    url: makeApiUrl(`space/${space_id}/delete`),
  });
};

export const comparePrompts = ({ prompt_id, data }) => {
  return http.post({
    url: makeApiUrl(`prompt/${prompt_id}/comparison`),
    data,
  });
};

export const getAllIterationData = ({
  comparison_id,
  model_compare_id,
  params,
}) => {
  return http.get({
    url: makeApiUrl(
      `comparison/${comparison_id}/iteration/${model_compare_id}`
    ),
    params,
  });
};

export const getComparisonResult = ({ comparison_id, params }) => {
  return http.get({
    url: makeApiUrl(`comparison/${comparison_id}/result/data`),
    params,
  });
};

export const updateComparisonNotification = ({ comparison_id, data }) => {
  return http.put({
    url: makeApiUrl(`comparison/${comparison_id}`),
    data,
  });
};

export const getPromptLogs = ({ prompt_id, params }) => {
  return http.get({
    url: makeApiUrl(`prompt/${prompt_id}/logs`),
    params,
  });
};

export const getAllTeamsCards = (team_Id) => {
  return http.get({
    url: makeApiUrl(`team/${team_Id}/payment/methods`),
  });
};

export const addNewCard = (team_Id) => {
  return http.get({
    url: makeApiUrl(`team/${team_Id}/payment/methods/url`),
  });
};

export const addPaymentMethod = ({ team_id, data }) => {
  return http.post({
    url: makeApiUrl(`team/${team_id}/payment/methods`),
    data,
  });
};
export const deleteCard = (team_id, method_id) => {
  return http.delete({
    url: makeApiUrl(`team/${team_id}/payment/${method_id}`),
  });
};

export const activeVatMethod = ({ team_id, method_id, data }) => {
  return http.put({
    url: makeApiUrl(`team/${team_id}/payment/${method_id}`),
    data,
  });
};

export const makePayment = ({ team_id, data }) => {
  return http.post({
    url: makeApiUrl(`team/${team_id}/proceed/payment`),
    data,
  });
};

export const updateAutoPay = ({ team_id, data }) => {
  return http.put({
    url: makeApiUrl(`team/${team_id}/auto/pay/trigger`),
    data,
  });
};

export const getAutoPayDetails = (team_id) => {
  return http.get({
    url: makeApiUrl(`team/${team_id}/auto/pay/trigger`),
  });
};

export const getDashboardDetails = ({ team_id, params = {} }) => {
  return http.get({
    url: makeApiUrl(`team/${team_id}/analytics`),
    params,
  });
};

export const getDashbaordErrors = (team_id, params = {}) => {
  return http.get({
    url: makeApiUrl(`team/${team_id}/errors`),
    params,
  });
};

export const updateDashboardErrors = ({ error_id, data }) => {
  return http.put({
    url: makeApiUrl(`prompt/analytics/${error_id}`),
    data,
  });
};

export const getCollectionDashboardDetails = ({
  collection_id,
  params = {},
}) => {
  return http.get({
    url: makeApiUrl(`collection/${collection_id}/analytics`),
    params,
  });
};

export const getCollectionDashboardPrompmts = (collection_id) => {
  return http.get({
    url: makeApiUrl(`collection/${collection_id}/model/prompts`),
  });
};

export const getCollectionDashboardErrors = (collection_id) => {
  return http.get({
    url: makeApiUrl(`collection/${collection_id}/errors`),
  });
};

export const fetchAdminAnalytics = (params = {}) => {
  return http.get({
    url: makeApiUrl(`get/execution/error`),
    params,
  });
};

export const getCodePayload = (prompt_id) => {
  return http.get({
    url: makeApiUrl(`prompt/${prompt_id}/payload`),
  });
};

export const getPromptEvaluation = ({ prompt_id, user_text }) => {
  return http.post({
    url: makeApiUrl(`prompt/${prompt_id}/evaluate`),
    data: {
      user_text,
      eval_parameters: [
        "Relevance",
        "Accuracy",
        "Fluency",
        "Completeness",
        "Conciseness",
        "Creativity",
        "Robustness",
        "Bias and Fairness",
        "Responsiveness",
      ],
    },
  });
};

export const getExpenseHistory = ({ team_id, params }) => {
  return http.get({
    url: makeApiUrl(`team/${team_id}/collection/cost`),
    params,
  });
};

export const getPromptExpenseHIstory = ({ collection_id, params }) => {
  return http.get({
    url: makeApiUrl(`collection/${collection_id}/prompt/cost`),
    params,
  });
};
