import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getCodePayload,
  getCollection,
  getTeam,
} from "../../../../services/app/api";
import snackbar from "../../../../services/app/snackbar";
import { copyToClipboard } from "../../../../services/generalFunctions";
import generateCode, {
  allLaguagesCode,
} from "../../../../services/generateCode";
import { useAuth } from "../../../Auth/AuthContext";
import { useThemeContext } from "../../../Themes/ThemeContextProvider";
import CodeHighlighter from "../../../UI/CodeHighlighter";
import { CopyIcon } from "../../../UI/IconPack";
import MuiSelect from "../../../UI/MuiSelect";

const Code = () => {
  const { colors, mode } = useThemeContext();
  const [language, setLanguage] = useState("");
  const params = useParams();
  const { activeTeam, id: userId } = useAuth();
  const [teamData, setTeamData] = useState({});
  const [collectionData, setCollectionData] = useState({});
  const [showCode, setShowCode] = useState(true);
  const [codePayload, SetCodePayload] = useState({});
  const editPromptsList = useSelector(
    (state) => state.collectionsSlice.editPromptsList
  );
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};
  const collectionVersion = `v${
    collectionData?.collection_versions
      ?.filter((c) => c?.current_active)[0]
      ?.version_name.split(`${collectionData?.collection_key}_V_`)[1]
  }`;

  const paramsToGenerateCode = {
    language,
    data: {
      collection_key: collectionData?.collection_key,
      version: collectionVersion,
      prompt_context_url: promptData?.context_url,
      team_url: teamData?.canonical_name,
      payload: codePayload?.payload,

      headers: {
        "Content-Type": "application/json",
      },
    },
  };

  useEffect(() => {
    getTeam(userId, activeTeam)
      .then((res) => setTeamData(res))
      .catch((error) => {
        snackbar.error({
          title: `${error.error && error.error.toString()}`,
        });
        setShowCode(false);
      });
    getCollection({ collection_Id: params?.collectionId })
      .then((res) => setCollectionData(res))
      .catch((error) => {
        snackbar.error({
          title: `${error.error && error.error.toString()}`,
        });
        setShowCode(false);
      });
    getCodePayload(promptData?.id)
      .then((res) => SetCodePayload(res))
      .catch((error) => {
        snackbar.error({
          title: `${error.error && error.error.toString()}`,
        });
        setShowCode(false);
      });
  }, []);
  const handleCopy = () => copyToClipboard(generateCode(paramsToGenerateCode));
  return (
    <div className="flex flex-col m-2 ">
      <div className="flex justify-between items-center my-4 px-2">
        <div className="w-32 h-8">
          <MuiSelect
            value={language}
            label="Language"
            onChange={(e) => setLanguage(e.target.value)}
            size="small"
            menuItems={Object.keys(allLaguagesCode)}
          />
        </div>
        <IconButton onClick={handleCopy} disabled={!language || !showCode}>
          <CopyIcon height={20} width={20} color={"#94969C"} />
        </IconButton>
      </div>
      {!!language && showCode ? (
        <CodeHighlighter
          language={language?.split(" ")[0].toLocaleLowerCase()}
          style={mode === "dark" ? "vs2015" : "a11yLight"}
          customStyle={{
            background: colors.bgPrimary,
            padding: "5px",
            fontSize: "12px",
            width: "100%",
            minWidth: "20%",
            overflow: "auto",
            maxHeight: "470px",
          }}
        >
          {generateCode(paramsToGenerateCode)}
        </CodeHighlighter>
      ) : (
        <div className="bg-primary m-2 rounded-lg p-6 h-96 w-[94%] flex items-center justify-center">
          <p className=" text-tertiary text-sm">No code to display</p>
        </div>
      )}
    </div>
  );
};

export default Code;
