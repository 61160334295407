import { Button } from "@mui/base";
import CloseIcon from "@mui/icons-material/Close";
import ExtensionIcon from "@mui/icons-material/ExtensionOutlined";
import { IconButton } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getElementWidthById } from "../../../../services/generalFunctions";
import {
  CautionIcon,
  CodeIcon,
  DocumentIcon,
  InfoIcon,
} from "../../../UI/IconPack";
import Code from "./Code";
import Document from "./Document";
import ExtensionsSelect from "./ExtensionsSelect";
import Information from "./Information";
import Warnings from "./Warnings";

const CollectionDrawer = (props) => {
  const {
    handleOpenCollectionDrawer,
    handleCloseCollectionDrawer,
    openCollectionDrawer,
  } = props;
  const newPromptsData = useSelector((state) => state.collectionsSlice);
  const { editPromptsList, activeWindow } = newPromptsData;
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};
  useEffect(() => {
    if (!editPromptsList?.length || activeWindow === "environment") {
      handleOpenCollectionDrawer(false);
    }
  }, [editPromptsList?.length, activeWindow]);

  const components = {
    Code: <Code />,
    Documentation: <Document />,
    Extension: <ExtensionsSelect />,
    Information: <Information />,
    "Warnings & Errors": <Warnings />,
  };
  const icons = {
    Code: <CodeIcon height={22} width={22} />,
    Documentation: <DocumentIcon height={20} width={20} />,
    Extension: <ExtensionIcon />,
    Information: <InfoIcon height={20} width={20} />,
  };

  return (
    <div
      className="flex h-screen border-t-1 border-primary sticky top-0 z-20"
      // onMouseLeave={handleCloseCollectionDrawer}
    >
      <div
        id={"collection-drawer"}
        className={` w-full pt-10 border-1 border-primary sticky top-0 flex flex-col items-center justify-between`}
      >
        <div>
          {Object.keys(icons).map((iconName) => (
            <Button
              key={iconName}
              onClick={() => handleOpenCollectionDrawer(iconName)}
              className={`rounded-lg p-2 mb-2 h-10 w-10 ${
                openCollectionDrawer == iconName
                  ? "bg-tertiary text-primary"
                  : "text-[#94969C]"
              } flex items-center flex-col justify-center `}
              title={iconName}
              disabled={
                !editPromptsList?.length || activeWindow !== "collection"
              }
            >
              {icons[iconName]}
            </Button>
          ))}
        </div>
        <Button
          onClick={() => handleOpenCollectionDrawer("Warnings & Errors")}
          className={`rounded-lg  mb-5 ${
            openCollectionDrawer === "Warnings & Errors"
              ? "bg-tertiary text-primary"
              : "text-[#94969C]"
          } p-2 h-10 w-10 flex items-center flex-col justify-center`}
          disabled={!editPromptsList?.length || activeWindow !== "collection"}
        >
          <CautionIcon
            color={promptData?.errors?.length ? "#FDB022" : "#94969C"}
            height={20}
            width={20}
          />
        </Button>
      </div>
      {openCollectionDrawer && (
        <div
          style={{
            right: `${getElementWidthById("collection-drawer")}px`,
          }}
          className="absolute bg-secondary  w-72 h-full border-x-1 border-primary "
        >
          <div className="sticky top-0 z-20 bg-secondary border-b-1 p-2 border-primary flex items-center justify-between w-full">
            <div>{openCollectionDrawer}</div>
            <IconButton
              onClick={handleCloseCollectionDrawer}
              title="Close"
              size="small"
            >
              <CloseIcon fontSize="small" className="text-tertiary" />
            </IconButton>
          </div>
          <div className="w-72">{components[openCollectionDrawer]}</div>
        </div>
      )}
    </div>
  );
};

export default CollectionDrawer;
