import { Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Hr = () => <div className="border-b-1 my-3 border-primary" />;
const Title = ({ children, className = "" }) => (
  <p
    className={`text-sm font-medium text-tertiary uppercase pb-10 ${className}`}
  >
    {children}
  </p>
);
const Label = ({ className = "", children }) => (
  <p className={`text-base font-medium text-primary  ${className}`}>
    {children}
  </p>
);
const Value = ({ className = "", children }) => (
  <p className={`text-base text-primary  ${className}`}>{children}</p>
);

const PromptReadOnly = () => {
  const editPromptsList = useSelector(
    (state) => state.collectionsSlice?.editPromptsList
  );
  const { t } = useTranslation();
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};
  const promptArray = promptData?.prompt_text?.prompt_data || [];
  let parameterArray = [...promptArray];
  const removed = parameterArray?.splice(0, 1) || [];
  return (
    <Stack spacing={2} p={5} pt={0}>
      <div className="bg-secondary border-1 rounded-lg border-secondary p-5">
        <Title>{t("prompt")}</Title>
        <Label>{t("prompt")}</Label>
        <Value>
          {promptArray?.length > 0 ? promptArray[0]?.action_val : "-"}
        </Value>
        <Hr />
        <Title>{t("variables")}</Title>
        <div className="flex gap-4 flex-wrap">
          {promptData?.variables.length > 0 ? (
            promptData?.variables.map((variable) => (
              <div
                key={variable?.id}
                className="flex items-center gap-2 text-primary bg-primary border-1 border-primary rounded-lg p-2"
              >
                {variable?.variable_key}
              </div>
            ))
          ) : (
            <div>-</div>
          )}
        </div>
      </div>
      <div className="bg-secondary border-1 rounded-lg border-secondary p-5">
        <Title>parameters</Title>
        {parameterArray?.length > 0 ? (
          parameterArray?.map((para, index) => (
            <div key={index}>
              <div className="flex justify-between gap-4">
                <div className="w-1/2">
                  <Label>{t("key")}</Label>
                  <Value>{para?.name || "-"}</Value>
                </div>
                <div className="w-1/2">
                  <Label>{t("value")}</Label>
                  <Value>{para?.action_val || "-"}</Value>
                </div>
              </div>
              <Hr />
            </div>
          ))
        ) : (
          <div>-</div>
        )}
      </div>
      <div className="bg-secondary border-1 rounded-lg border-secondary p-5">
        <Title>{t("advance settings")}</Title>
        <div className="w-full">
          <Label>Context</Label>
          <Value>{promptData?.context_name || "-"}</Value>
        </div>
        <div className="w-full">
          <Label>Cache</Label>
          <Value>
            {promptData?.cache_setting?.active
              ? `Enabled (Cache Count: ${
                  promptData?.cache_setting?.cache_count || 0
                })`
              : "Disabled"}{" "}
            - Refreshed every {promptData?.cache_setting?.interval}
          </Value>
          <Value></Value>
        </div>
        <div className="flex justify-between gap-4 mt-4">
          <div className="w-1/2">
            <Label>Max token to use</Label>
            <Value>{promptData?.prompt_setting?.max_token || "-"}</Value>
          </div>
          <div className="w-1/2">
            <Label>Temperature</Label>
            <Value>{promptData?.prompt_setting?.temperature || "-"}</Value>
          </div>
        </div>
        <div className="flex justify-between gap-4 mt-4">
          <div className="w-1/2">
            <Label>Variation</Label>
            <Value>{promptData?.variation_count || "-"}</Value>
          </div>
          <div className="w-1/2"></div>
        </div>
      </div>
    </Stack>
  );
};

export default PromptReadOnly;
