import ChatGPT from "../Images/ChatGPT.svg";
import ChatGptWhite from "../Images/ChatGptWhite.svg";
import AI21 from "../Images/ai21.png";
import anthropic from "../Images/anthropic.svg";
import Cohere from "../Images/cohere.png";
import Gemini from "../Images/gemini.svg";
import Palm from "../Images/palm.png";
import Stability from "../Images/stability.png";
import { AtlassianIcon, DriveGoogleIcon } from "../components/UI/IconPack";

export const MODEL_MAX_TOKEN_COUNT = {
  open_ai: 3500,
  google_palm: 10000,
  gemini: 10000,
  cohere: 3500,
  ai21: 8000,
  anthropic: 4000,
};

export const emptyPrompt = () => ({
  promptTabs: [],
  promptPanels: {},
  name: "untitled",
  tags: [],
  prompt_text: {
    prompt_data: [
      {
        name: "",
        action_val: "",
      },
    ],
  },
  category: "",
  id: `newPromptId${Math.random()}`,
  output_keys: {
    json: {
      text: "give output in 100 words",
    },
    csv: ["name", "date"],
    xml: `<data>
    <title>title here</title>
    <description>description here</description>
</data>`,
  },
  isSaved: false,
  isEdited: false,
  llmModel: "",
  llmModelName: "",
  llmVersion: "",
  llmVersionName: "",
  processType: "",
  outputFormat: "json",
  promptOutput: "",
  environment_id: "",
  description: "",
  variationCount: 1,
  imageSize: "256x256",
  activeScreen: "Prompt Data",
  expandFormatter: true,
  active: true,
  errors: [],
  prompt_setting: {
    temperature: 0.5,
    max_token: 3000,
  },
  context: "",
  cache_setting: {
    active: false,
    interval: "day",
  },
  compare_data: [],
  iteration_cnt: 1,
  compareResponse: {},
  iterationResponse: [],
  compareResultResponse: {},
  comparisonProgress: false,
});

export const emptyEnv = () => ({
  id: `newEnvId${Math.random()}`,
  name: "untitled",
  desciption: "",
  variables: [
    {
      id: "",
      key: "",
      value: "",
      created_at: "",
      updated_at: "",
      file_id: "",
      type: "text",
    },
  ],
});

export const emptyLLMKey = () => ({
  priority: "",
  name: "",
  created_by: {
    last_name: "",
    first_name: "",
    id: "",
    email: "",
  },
  user_id: "",
  updated_at: "",
  id: `newLLMId${Math.random()}`,
  llm_info: {
    description: "",
    active: false,
    name: "",
    updated_at: "",
    id: "",
    created_at: "",
    identification_key: "",
  },
  api_key: "",
  created_at: "",
  keyError: "",
  descriptionError: "",
  priorityError: "",
});

export const emptyContextData = () => ({
  id: `newContextId${Math.random()}`,
  name: "untitled",
  context: "",
  role: "system",
  key: "context",
  variables: [],
});

export const emptyAssistantData = () => ({
  id: `newAssistant${Math.random()}`,
  name: "assistant",
  assistance_text: {
    languages: ["English"],
    tone: "Formal",
    constraints: "",
    generalInstructions: "",
  },
  description: "",
  modelVersionId: "",
  responseType: "",
  responseString: "",
  tools: ["code_interpreter"],
  file_ids: [],
  file_tags: [],
  instructions: "",
  activeScreen: "Assistant Data",
  expandFormatter: true,
  llmModelName: "",
  llmVersionName: "",
  llmModelId: "",
  chat: [],
  conversationData: {
    execution_role: "system",
    conversation_id: null,
    file_ids: [],
    conversation_message: "",
    input_message: "",
    loading: false,
  },
});

export const availableImageSizes = ["256x256", "512x512", "1024x1024"];
export const dalle3ImageSizes = ["1024x1024"];
export const imageVariations = [1, 2, 3, 4];

export const llmModelsIcon = (size = "50px", mode = "") => {
  return {
    open_ai: (
      <img
        height={size}
        width={size}
        src={
          mode == "white"
            ? ChatGptWhite
            : mode == "dark"
            ? ChatGPT
            : localStorage.getItem("themeMode") !== "dark"
            ? ChatGPT
            : ChatGptWhite
        }
        alt="open ai"
      />
    ),
    google_palm: <img height={size} width={size} src={Palm} alt="palm" />,
    cohere: <img height={size} width={size} src={Cohere} alt="palm" />,
    ai21: (
      <img
        height={size}
        width={size}
        src={AI21}
        alt="palm"
        className="rounded-lg"
      />
    ),
    stability: (
      <img
        height={size}
        width={size}
        src={Stability}
        alt="palm"
        className="rounded-lg"
      />
    ),
    gemini: <img height={size} width={size} src={Gemini} alt="gemini ai" />,
    anthropic: (
      <img height={size} width={size} src={anthropic} alt="anthropic ai" />
    ),
  };
};

export const defaultOutputKeyValues = {
  json: {
    data: [
      {
        title: "give 20 character title",
        description: "give 50 character description",
      },
    ],
  },
  csv: ["name", "date"],
  xml: `<data>
  <title>title here</title>
  <description>description here</description>
</data>`,
};

export const dataRoomFileSupports = [
  "C",
  "CPP",
  "CSV",
  "DOCX",
  "HTML",
  "JAVA",
  "JSON",
  "MD",
  "PDF",
  "PHP",
  "PPTX",
  "PY",
  "RB",
  "TEX",
  "TXT",
  "CSS",
  "JPEG",
  "JPG",
  "JS",
  "GIF",
  "PNG",
  "TAR",
  "TS",
  "XLSX",
  "XML",
  "ZIP",
  "PLAIN",
];

export const assistantsLanguages = [
  "Afrikaans",
  "Arabic",
  "Armenian",
  "Azerbaijani",
  "Belarusian",
  "Bosnian",
  "Bulgarian",
  "Catalan",
  "Chinese",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "English",
  "Estonian",
  "Finnish",
  "French",
  "Galician",
  "German",
  "Greek",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Icelandic",
  "Indonesian",
  "Italian",
  "Japanese",
  "Kannada",
  "Kazakh",
  "Korean",
  "Latvian",
  "Lithuanian",
  "Macedonian",
  "Malay",
  "Marathi",
  "Maori",
  "Nepali",
  "Norwegian",
  "Persian",
  "Polish",
  "Portuguese",
  "Romanian",
  "Russian",
  "Serbian",
  "Slovak",
  "Slovenian",
  "Spanish",
  "Swahili",
  "Swedish",
  "Tagalog",
  "Tamil",
  "Thai",
  "Turkish",
  "Ukrainian",
  "Urdu",
  "Vietnamese",
  "Welsh",
  "Wu",
];

export const assistantsTone = [
  "Formal",
  "Casual",
  "Informative",
  "Playful",
  "Professional",
  "Friendly",
  "Serious",
  "Humorous",
  "Persuasive",
  "Instructional",
  "Poetic",
  "Scientific",
  "Emotional",
];

export const assistantScope = [
  "Content Generation",
  "Art Creation",
  "Music Composition",
  "Design Assistance",
  "Idea Generation",
  "Copywriting",
  "Storytelling",
  "Poetry Generation",
  "Language Translation and Adaptation",
  "Interactive Experiences",
  "Educational Content Creation",
  "AI Art Collaboration",
];

export const themeFontSize = [
  {
    label: "Small (12px)",
    value: "12px",
  },
  {
    label: "Medium (16px)",
    value: "16px",
  },
  {
    label: "Large (18px)",
    value: "18px",
  },
];

export const overlaysOption = [
  { value: "over-page", label: "Over page" },
  { value: "side-page", label: "Side of page" },
];

export const bubblePosition = [
  { value: "bottom-right", label: "Bottom Right" },
  { value: "bottom-left", label: "Bottom Left" },
  { value: "bottom-center", label: "Bottom Center" },
  { value: "top-right", label: "Top Right" },
  { value: "top-left", label: "Top Left" },
  { value: "top-center", label: "Top Center" },
];

export const themeFontFamilies = [
  { value: "Alegreya", label: "Alegreya" },
  { value: "Amatic SC", label: "Amatic SC" },
  { value: "Arial", label: "Arial" },
  { value: "Arial Black", label: "Arial Black" },
  { value: "Barlow", label: "Barlow" },
  { value: "Baskerville", label: "Baskerville" },
  { value: "Bebas Neue", label: "Bebas Neue" },
  { value: "Cabin", label: "Cabin" },
  { value: "Comfortaa", label: "Comfortaa" },
  { value: "Courier", label: "Courier" },
  { value: "DM Serif Display", label: "DM Serif Display" },
  { value: "Dancing Script", label: "Dancing Script" },
  { value: "EB Garamond", label: "EB Garamond" },
  { value: "Fira Sans", label: "Fira Sans" },
  { value: "Georgia", label: "Georgia" },
  { value: "Gill Sans", label: "Gill Sans" },
  { value: "Helvetica", label: "Helvetica" },
  { value: "Impact", label: "Impact" },
  { value: "Inconsolata", label: "Inconsolata" },
  { value: "Inter", label: "Inter" },
  { value: "Josefin Sans", label: "Josefin Sans" },
  { value: "Karla", label: "Karla" },
  { value: "Lato", label: "Lato" },
  { value: "Libre Baskerville", label: "Libre Baskerville" },
  { value: "Lora", label: "Lora" },
  { value: "Merriweather", label: "Merriweather" },
  { value: "Monoton", label: "Monoton" },
  { value: "Montserrat", label: "Montserrat" },
  { value: "Muli", label: "Muli" },
  { value: "Notable", label: "Notable" },
  { value: "Noto Serif Georgian", label: "Noto Serif Georgian" },
  { value: "Nunito", label: "Nunito" },
  { value: "Open Sans", label: "Open Sans" },
  { value: "Oswald", label: "Oswald" },
  { value: "Pacifico", label: "Pacifico" },
  { value: "Palatino", label: "Palatino" },
  { value: "Playfair Display", label: "Playfair Display" },
  { value: "Poppins", label: "Poppins" },
  { value: "Quicksand", label: "Quicksand" },
  { value: "Raleway", label: "Raleway" },
  { value: "Roboto Slab", label: "Roboto Slab" },
  { value: "Rock Salt", label: "Rock Salt" },
  { value: "Rokkitt", label: "Rokkitt" },
  { value: "Source Serif Pro", label: "Source Serif Pro" },
  { value: "Space Grotesk", label: "Space Grotesk" },
  { value: "Tahoma", label: "Tahoma" },
  { value: "Times New Roman", label: "Times New Roman" },
  { value: "Ubuntu", label: "Ubuntu" },
  { value: "Verdana", label: "Verdana" },
  { value: "Work Sans", label: "Work Sans" },
  { value: "Yantramanav", label: "Yantramanav" },
];

export const colorsOfFile = {
  C: "#db94ae",
  CPP: "#1aa87",
  CSV: "#bd9938",
  DOCX: "#4a0b53",
  HTML: "#637f0a",
  JAVA: "#dc875d",
  JSON: "#e80fe9",
  MD: "#5de96c",
  PDF: "#346874",
  PHP: "#48c8c",
  PPTX: "#4ce552",
  PY: "#948643",
  RB: "#96102a",
  TEX: "#c571d0",
  TXT: "#57bee1",
  CSS: "#e38d51",
  JPEG: "#fc4f29",
  JPG: "#cc6790",
  JS: "#9580f2",
  PNG: "#15d8a0",
  TAR: "#41772a",
  GIF: "#41772a",
  TS: "#231d66",
  XLSX: "#b01220",
  XML: "#176728",
  ZIP: "#4cd181",
  PLAIN: "#a6ba4d",
};

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const weeks = [
  "1st Week",
  "2nd Week",
  "3rd Week",
  "4th Week",
  "5th Week",
];

export const hoursInDay = [
  "12 AM",
  "01 AM",
  "02 AM",
  "03 AM",
  "04 AM",
  "05 AM",
  "06 AM",
  "07 AM",
  "08 AM",
  "09 AM",
  "10 AM",
  "11 AM",
  "12 PM",
  "01 PM",
  "02 PM",
  "03 PM",
  "04 PM",
  "05 PM",
  "06 PM",
  "07 PM",
  "08 PM",
  "09 PM",
  "10 PM",
  "11 PM",
];

export const externalDataIcons = {
  confluence: <AtlassianIcon height={40} width={40} />,
  google_drive: <DriveGoogleIcon height={40} width={40} />,
};
