import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleChangEditPrompt } from "../../../../store/collectionsSlice";
import MuiSelect from "../../../UI/MuiSelect";
import CsvFormatter from "./CsvFormatter";

import JsonFormatter from "./JsonFormatter";
import XmlFormatter from "./XmlFormatter";

const OutputFormat = (props) => {
  const { error, onFocus } = props;

  const editPromptsList = useSelector(
    (state) => state.collectionsSlice.editPromptsList
  );
  const modelsVersionsList = useSelector(
    (state) => state.collectionsSlice.modelsVersionsList
  );
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};

  const [activeScreen, setActiveScreen] = useState("Preview");
  const [menuOptions, setMenuOptions] = useState([
    { label: "JSON", value: "json" },
    { label: "XML", value: "xml" },
  ]);
  const isImageTypeModel =
    modelsVersionsList?.filter((i) => i?.id === promptData?.llmVersion)[0]
      ?.model_type === "Image";
  const dispatch = useDispatch();

  const handleChangeOutputFormat = (event) => {
    let data = {
      ...promptData,
      outputFormat: event.target.value,
      promptOutput: "",
    };
    dispatch(handleChangEditPrompt(data));
    setActiveScreen("Preview");
  };
  const handleExpand = () => {
    let data1 = {
      ...promptData,
      expandFormatter: !promptData?.expandFormatter,
    };
    dispatch(handleChangEditPrompt(data1));
  };

  useEffect(() => {
    if (isImageTypeModel) {
      setMenuOptions([{ label: "JSON", value: "json" }]);
      let data = {
        ...promptData,
        outputFormat: "json",
      };
      dispatch(handleChangEditPrompt(data));
    } else {
      setMenuOptions([
        { label: "JSON", value: "json" },
        { label: "CSV", value: "csv" },
        { label: "XML", value: "xml" },
      ]);
    }
  }, [isImageTypeModel]);

  const formatters = {
    json: <JsonFormatter activeScreen={activeScreen} />,
    csv: <CsvFormatter activeScreen={activeScreen} />,
    xml: <XmlFormatter activeScreen={activeScreen} />,
  };

  return (
    <div>
      <div
        className={`flex items-center justify-between p-2 bg-tertiary  ${
          promptData?.expandFormatter && !!promptData?.outputFormat
            ? "rounded-t-lg border-b-1 border-primary "
            : "rounded-lg border-none"
        }`}
      >
        <div className="flex items-center  gap-6  ">
          <p className="text-md-medium text-primary">Set Response Format</p>
          <div className="w-40">
            <MuiSelect
              label={"Response Format"}
              value={promptData?.outputFormat}
              onChange={handleChangeOutputFormat}
              error={error}
              onFocus={onFocus}
              menuItems={menuOptions}
            />
          </div>
        </div>
        <div className="flex items-center gap-4">
          {promptData?.outputFormat && promptData?.expandFormatter && (
            <Button
              startIcon={<VisibilityOutlined />}
              variant={"text"}
              color={"secondary"}
              onClick={() =>
                setActiveScreen((ini) =>
                  ini === "Preview" ? promptData?.outputFormat : "Preview"
                )
              }
              sx={{ textTransform: "capitalize" }}
            >
              {activeScreen}
            </Button>
          )}
          {promptData?.outputFormat && (
            <Button
              variant={"text"}
              color={"secondary"}
              onClick={handleExpand}
              sx={{ textTransform: "capitalize" }}
              endIcon={
                promptData?.expandFormatter ? (
                  <KeyboardArrowUp className="text-grayEditor400" />
                ) : (
                  <KeyboardArrowDown className="text-grayEditor400" />
                )
              }
            >
              {promptData?.expandFormatter ? "Collapse" : "Expand"}
            </Button>
          )}
        </div>
      </div>
      <div className="bg-secondary rounded-b-lg">
        {promptData?.expandFormatter && formatters[promptData?.outputFormat]}
      </div>
    </div>
  );
};

export default OutputFormat;
