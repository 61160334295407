import React from "react";

import chevronRightIcon from "@/Images/chevron-right.png";

import { data, data2 } from "./data";

import { Link } from "react-router-dom";
import Welcome from "../common/Welcome";

const Dashboard = (props) => {
  const { showWelcome = true } = props;

  return (
    <>
      <div className={`${!showWelcome && ""} `}>
        {showWelcome && <Welcome />}
      </div>

      <div className="flex flex-row justify-between items-start  -mt-[8%] px-[5%] md:space-x-4 w-full ">
        <div className="md:w-1/2 w-full   overflow-hidden border border-primary rounded-[12px] flex flex-col">
          <div className="w-full max-h-[94px] px-[20px] py-[24px] border-b border-primary bg-secondary">
            <h1 className="text-primary text-md">Get Started with Scalaix</h1>
            <h2 className="text-tertiary text-sm">
              Begin with the basics. Get up and running quickly by checking off
              common tasks.
            </h2>
          </div>

          {data.map((item, index) => (
            <Link
              key={index}
              to={item.to}
              className="w-full max-h-[96px] px-[24px] py-[20px] border-b border-primary bg-primary"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                  <div className="h-[48px] w-[48px] rounded-lg border border-primary flex items-center justify-center">
                    <img
                      src={item.icon}
                      alt="icon"
                      className="object-contain h-[28px] w-[28px]"
                    />
                  </div>
                  <div>
                    <h1 className="text-primary text-md">{item.title}</h1>
                    <h2 className="text-tertiary text-sm">
                      {item.description}
                    </h2>
                  </div>
                </div>
                <img src={chevronRightIcon} className="h-[24px] w-[24px]" />
              </div>
            </Link>
          ))}
        </div>

        <div className="md:w-1/2 w-full  flex flex-col space-y-4 overflow-auto">
          <div className="w-full h-[315px] bg-red-50 border border-primary rounded-md opacity-1">
            {/* <h1 className="display-md-semibold text-center text-black">
          Demo Video
        </h1> */}
          </div>

          {/* Resources Section */}
          <div className="w-full h-[315px] flex flex-col rounded-[12px] border border-primary overflow-hidden">
            <div className="w-full h-[72px] p-[20px] border-b border-primary bg-secondary">
              <h1 className="text-primary text-md">Resources</h1>
            </div>
            {data2.map((item, index) => (
              <div
                key={index}
                className="w-full h-[88px] p-[20px] border-b border-primary"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-4">
                    <div className="h-[48px] w-[48px] rounded-lg border border-primary flex items-center justify-center">
                      <img
                        src={item.icon}
                        alt=""
                        className="object-contain h-[28px] w-[28px]"
                      />
                    </div>
                    <h1 className="text-primary text-md">{item.title}</h1>
                  </div>
                  <img src={chevronRightIcon} className="h-[24px] w-[24px]" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
