import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getMyCollections } from "../../../services/app/api";
import snackbar from "../../../services/app/snackbar";
import { handleLoading } from "../../../store/collectionsSlice";
import { useAuth } from "../../Auth/AuthContext";
import Dashboard from "./dashboard";
import DashboardData from "./dashboardData";

const RenderDashboard = () => {
  const [collections, setCollections] = useState({});
  const { activeTeam, id: userId } = useAuth();
  const dispatch = useDispatch();
  const callCollections = (params = {}) => {
    dispatch(handleLoading(true));

    getMyCollections({
      team_id: activeTeam,
      params: { ...params, current: true, limit: 9 },
    })
      .then((res) => {
        setCollections(res);
      })
      .catch((error) => {
        snackbar.error({
          title: `${(error.error && error.error.toString()) || "No Data"}`,
        });
      })
      .finally(() => {
        dispatch(handleLoading(false));
      });
  };

  useEffect(() => {
    callCollections();
  }, [activeTeam]);

  return (
    <>{collections?.items?.length == 0 ? <Dashboard /> : <DashboardData />}</>
  );
};

export default RenderDashboard;
