import assistantIcon from "../../../Images/assistant-icon.svg";
import automationIcon from "../../../Images/automation-icon.svg";
import createPromptIcon from "../../../Images/create-promp-icon.svg";
import dataroomIcon from "../../../Images/dataroom-icon.svg";
import featuredIcon from "../../../Images/Featured icon.svg";
import integrationIcon from "../../../Images/integrationIcon.svg";
import promptIcon from "../../../Images/prompt-icon.svg";
import promptToolsIcon from "../../../Images/promptToolsIcon.svg";

export const data = [
  {
    icon: featuredIcon,
    to: "/onboard/settings",
    title: "Invite your first member",
    description: "Add your teammates to your team to work together.",
  },
  {
    icon: promptIcon,
    to: "/onboard/templates",
    title: "Explore our prompt templates",
    description: "Dive into the wide variety of templates to start.",
  },
  {
    icon: createPromptIcon,
    to: "/onboard/collections",
    title: "Create your first prompt",
    description: "Dive into the prompt editor and start creating.",
  },
  {
    icon: dataroomIcon,
    to: "/onboard/data-room",
    title: "Add content to your data room",
    description: "Provide information to feed the AI with your information.",
  },
  {
    icon: assistantIcon,
    to: "/onboard/assistants",
    title: "Create your first AI assistant",
    description:
      "Create a powerful assistant to answer your customers' questions.",
  },
  {
    icon: automationIcon,
    to: "",
    title: "Explore the power of automations",
    description: "Start creating advanced sequences with the automation tool.",
  },
];

export const data2 = [
  {
    icon: promptToolsIcon,
    title: "Advanced prompt tools",
  },
  {
    icon: assistantIcon,
    title: "Everything about AI Assistants",
  },
  {
    icon: integrationIcon,
    title: "Working with integrations",
  },
];

export const data3 = [
  {
    icon: featuredIcon,
    to: "/onboard/settings",
    title: "Invite a team member",
  },
  {
    icon: promptIcon,
    to: "/onboard/templates",
    title: "Explore our prompt templates",
  },
  {
    icon: createPromptIcon,
    to: "/onboard/collections",
    title: "Create a prompt",
  },
  {
    icon: dataroomIcon,
    to: "/onboard/data-room",
    title: "Add content to your data room",
  },
  {
    icon: assistantIcon,
    to: "/onboard/assistants",
    title: "Create an AI assistant",
  },
  {
    icon: automationIcon,
    to: "",
    title: "Explore the power of automations",
  },
];
