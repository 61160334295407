import { useThemeContext } from "@Components/Themes/ThemeContextProvider";
import Navigation from "@Components/onboarding/Navigation";
import CohereLogo from "@Images/CohereLogo.png";
import Content from "@Images/Content.png";
import Dalle from "@Images/Dalle.png";
import Frame3709 from "@Images/Frame3709.png";
import Frame3710 from "@Images/Frame3710.png";
import Ai21lab from "@Images/ai21lab.png";
import BgImage from "@Images/bg-images.png";
import OpenAILogo from "@Images/openAIlogo.png";
import TopBlockBg from "@Images/top-block-bg.png";
import UserIcons from "@Images/user-icons.png";
import { getBenchMarkResult } from "@Services.App/api";
import snackbarService from "@Services.App/snackbar";
import { llmModelsIcon } from "@Services/constants";
import { handleLoading } from "@Store/collectionsSlice";
import { NavbarLogo, NonSelectedIcon, SelectedRoundIcon } from "@UI/IconPack";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./Features.scss";
var dataFeed = [
  {
    icon: UserIcons,
    title: "Made for teams",
    desc: "Lorem ipsum dolor sit amet consectetur. Non diam pharetra et nec convallis quis leo tempor. Quis maecenas volutpat bibendum nec amet aliquet facilisi semper.",
    logo: "",
  },
  {
    icon: UserIcons,
    title: "Free credits to start",
    desc: "Lorem ipsum dolor sit amet consectetur. Non diam pharetra et nec convallis quis leo tempor. Quis maecenas volutpat bibendum nec amet aliquet facilisi semper.",
    logo: "",
  },
  {
    icon: UserIcons,
    title: "Advanced prompt creation",
    desc: "Lorem ipsum dolor sit amet consectetur. Non diam pharetra et nec convallis quis leo tempor. Quis maecenas volutpat bibendum nec amet aliquet facilisi semper.",
    logo: "",
  },
  {
    icon: UserIcons,
    title: "Version history",
    desc: "Lorem ipsum dolor sit amet consectetur. Non diam pharetra et nec convallis quis leo tempor. Quis maecenas volutpat bibendum nec amet aliquet facilisi semper.",
    logo: "",
  },
];
const Features = () => {
  const navigate = useNavigate();
  const [benchmarkResults, setBenchmarkResults] = useState([]);
  const dispatch = useDispatch();
  const { toggleColorMode, mode } = useThemeContext();
  var dataLogo = [Ai21lab, CohereLogo, Dalle, Frame3709, Frame3710, OpenAILogo];
  const [orderBy, setOrderBy] = useState("cost");
  const getBackMarkResultHandler = (type) => {
    dispatch(handleLoading(true));
    getBenchMarkResult({
      params: {
        order_by: type,
      },
    })
      .then((res) => {
        setBenchmarkResults(res?.compare_data ? res?.compare_data : []);
      })
      .catch((error) => {
        snackbarService.error({
          title: `${(error.error && error.error.toString()) || "No Data"}`,
        });
      })
      .finally(() => {
        dispatch(handleLoading(false));
      });
  };

  function roundToDecimalPlaces(num, precision) {
    var multiplier = Math.pow(10, precision);
    return Math.round(num * multiplier) / multiplier;
  }
  useEffect(() => {
    getBackMarkResultHandler(orderBy);
  }, []);
  return (
    <div className="">
      {false ? (
        <div>
          <Navigation />
        </div>
      ) : (
        <div style={{ background: "black" }}>
          <div
            className=""
            style={{
              backgroundImage: `url(${BgImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="container-main-cur ">
              <div className="px-[5%]">
                <Navigation />
              </div>
              <div className="px-[5%]  flex justify-center items-center flex-col gap-6 py-16 text-white ">
                <div className="display-lg-semibold text-center">
                  Build your next-gen AI Stack
                </div>
                <div className="text-lg-regular text-center">
                  Accelerate how you build and deploy AI using best-of-breed
                  components with our open AI <br></br> integrations that give
                  you the freedom to adapt as your needs evolve.
                </div>
                <div>
                  <Button
                    variant="blackbutton"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Get Started
                  </Button>
                </div>
              </div>
            </div>
            <div className="px-[5%] flex justify-center">
              <img
                class="img-res"
                style={{ height: "auto", objectFit: "cover" }}
                src={TopBlockBg}
              />
            </div>
          </div>

          <div className="container-main-cur py-32 px-[5%] ">
            <div
              className="text-md-medium text-center pb-8 "
              style={{ color: "#94969C" }}
            >
              Access multiple AI and Large Language Models (LLMs) seamlessly via
              a single easy to use interface.
            </div>

            <div className="px-[5%] grid-outer-block">
              {dataLogo.map((item) => {
                return (
                  <div style={{ width: "fit-content" }}>
                    <img src={item} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="relative pl-[5%]">
            <div className="container-main-cur">
              <div className="flex flex-col gap-2">
                <div className="text-md-semibold text-[#94969C]">Features</div>
                <div className="display-md-semibold text-white">
                  Overflowing with useful features
                </div>
                <div className="text-xl-regular text-[#94969C]">
                  Unlock the Power of Multiple LLMs with Our All-in-One API
                  Interface: <br></br>Built-In Prompt Engineering, Robust
                  Testing, and Seamless Scalability Support!
                </div>
              </div>
              <div className="block2grid2 ">
                <div className="data-grid-blok2 my-auto">
                  {dataFeed.map((item) => {
                    return (
                      <div className="flex gap-3 flex-col ">
                        <div>
                          <img src={item.icon} />
                        </div>
                        <div className="text-xl-semibold text-white">
                          {item.title}
                        </div>
                        <div className="text-md-regular text-[#94969C]">
                          {item.desc}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div>
                  <img
                    style={{
                      // borderRadius: "18px",
                      //   height: "500px",
                      objectFit: "",
                      width: "100%",
                    }}
                    src={Content}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container-main-cur py-8 ">
            <div className="flex flex-col gap-2 px-[5%]">
              <div className="text-md-semibold text-[#94969C]">
                LLMs Comparison
              </div>
              <div className="display-md-semibold text-white">
                Discover which LLM is best suited for your specific needs
              </div>
              <div className="text-xl-regular text-[#94969C]">
                Scalaix enables you to test and compare various LLMs according
                to your unique <br></br> requirements and seamlessly switch
                between them to manage your costs and <br></br>response times
                effectively.
              </div>
            </div>
            <div className="data-grid pt-[64px] px-[5%]">
              <div className="flex flex-col gap-4">
                <div
                  onClick={() => {
                    setOrderBy("time");
                    getBackMarkResultHandler("time");
                  }}
                  className={
                    orderBy == "time" ? "none-selected-block" : "selected-block"
                  }
                >
                  <div className="block-inner1">
                    {orderBy == "time" ? (
                      <SelectedRoundIcon />
                    ) : (
                      <NonSelectedIcon />
                    )}
                  </div>
                  <div className="text-md-medium text-white">Response time</div>
                </div>
                <div
                  onClick={() => {
                    setOrderBy("cost");
                    getBackMarkResultHandler("cost");
                  }}
                  className={
                    orderBy !== "time"
                      ? "none-selected-block"
                      : "selected-block"
                  }
                >
                  <div className="block-inner1">
                    {orderBy !== "time" ? (
                      <SelectedRoundIcon />
                    ) : (
                      <NonSelectedIcon />
                    )}
                  </div>
                  <div className="text-md-medium text-white">Cost</div>
                </div>
              </div>

              {benchmarkResults.map((item, index) => {
                return (
                  <div className="model-data-block">
                    <div>
                      <div className="data-num">#{index + 1}</div>
                    </div>
                    <div className="flex gap-3">
                      <div>
                        {
                          llmModelsIcon(
                            "30px",
                            mode === "light" ? "black" : "white"
                          )[item?.model]
                        }
                      </div>
                      <div className="text-md-medium ">{item?.model_name}</div>
                    </div>

                    <div>
                      <div className="text-md-medium data-tittle">
                        Average response time
                      </div>
                      <div className="text-md-regular">
                        {roundToDecimalPlaces(item?.average_response_time, 5)}
                        sec
                      </div>
                    </div>
                    <div>
                      <div className="text-md-medium data-tittle">
                        Average cost
                      </div>
                      <div className="text-md-regular">
                        <div>
                          ${roundToDecimalPlaces(item?.average_cost, 5)}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="container-main-cur ">
            <div className="datafooter items-center justify-between py-[32px] px-[5%]  w-full">
              <div className="flex items-center ">
                <div>
                  <NavbarLogo width={"142px"} height={"32px"} />
                </div>
              </div>
              <div className="text-md-regular  text-[#94969C]">
                © 2024 Scalaix. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Features;
